import React from "react"
import { graphql } from "gatsby"
import { css } from "styled-components"

import ContentLayout from "../components/ContentLayout"
import CategoryList from "../components/CategoryList"

import Icon from "../svg/menu/erettsegi.svg"

const levelToString = level => {
  switch (level) {
    case "middle":
      return "Középszintű"
    case "high":
      return "Emeltszintű"
    default:
      return "Középszintű"
  }
}

const getTitleFromFrontmatter = (year, level, month) => (
  <>
    <strong
      css={css`
        font-weight: bold;
      `}
    >
      {levelToString(level)}
    </strong>
    {` - ${year}. ${month}`}
  </>
)

const ErettsegiPage = ({
  data: {
    math: { nodes: math },
  },
}) => {
  const mathNew = math.map(m => {
    const f = { ...m.frontmatter }
    f.title = getTitleFromFrontmatter(f.year, f.level, f.month)
    return { ...m, frontmatter: f }
  })

  return (
    <ContentLayout icon={Icon} title="Érettségi">
      {[
        {
          title: "Matematika",
          data: mathNew,
        },
      ].map(({ title, data }) => (
        <CategoryList
          key={title}
          title={title}
          items={data}
          sorter={(a, b) => {
            const levelA = a.frontmatter.level === "high" ? 1 : 0
            const levelB = b.frontmatter.level === "high" ? 1 : 0
            return `${levelA}`.localeCompare(`${levelB}`)
          }}
        />
      ))}
    </ContentLayout>
  )
}

export default ErettsegiPage

export const query = graphql`
  query erettsegi {
    math: allMdx(
      filter: {
        frontmatter: { category: { eq: "math" } }
        slug: { regex: "/^(erettsegi)/.*$/" }
      }
    ) {
      nodes {
        id
        path: slug
        frontmatter {
          year
          level
          month
        }
      }
    }
  }
`
